.delete-button {
  margin-top: 20px;
  font-size: 1.3em;
}

.artpiece {
  color: black;
  text-decoration: none;
}

.artpiece-title {
  color: black;
}

.artpiece-uploaded {
  font-size: 1.3em;
}

@media screen and (max-width: 500px) {
  .artpiece-title {
    font-size: 1.7em;
  }

  .artpiece-uploaded {
    font-size: 1.2em;
  }
}
