.signup-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2% 0% 1%;
}

legend {
  font-size: 1.7em;
  margin-bottom: 2%;
}

.reg-flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1% 5%;
}

.register-page {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#reg-password, #reg-email, #reg-first-name, #reg-last-name, #reg-username {
  margin: 5px;
  padding: 1px;
  font-size: 1.5em;
  border: black solid 1px;
}

.error-message {
  color: red;
}

.reg-label {
  margin-right: 5px;
  font-size: 1.5em;
  margin-bottom: 5px;
}

.login-link {
  font-size: 1em;
  display: inline-block;
  padding: 0 2%;
}

.member {
  font-size: 1.5em;
}

.reg-button-back {
  font-size: 1.5em;
  margin: 1% 0%;
  border-radius: 30%;
}

.reg-button {
  font-size: 1.5em;
  margin-top: 10px;
}

@media screen and (max-width: 850px) {
  .register-page {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

@media screen and (max-width: 420px) {
  #reg-password, #reg-email, #reg-first-name, #reg-last-name, #reg-username {
    margin: 5px 0px;
    padding: 1px;
    font-size: 1em;
    border: black solid 1px;
  }
  .reg-label {
    margin-right: 5px;
    font-size: 1.2em;
  }
  .reg-button {
    font-size: 1.3em;
    margin-top: 10px;
  }
  legend {
    font-size: 1.4em;
    margin-bottom: 2%;
  }
  .member {
    font-size: 1.3em;
  }
  .error-message {
    font-size: 0.8em;
  }
}