* {
  box-sizing: border-box;
}

.name {
  color: black;
  display: inline-block;
  font-size: 25px;
  margin: 0px 0px 8px auto;
  text-decoration: none;
  font-weight: bold;
}

.gallery {
  border-bottom: 2px solid black;
  align-content: center;
  color: black;
}

.count {
  margin: 0 20px 0;
}

.gallery-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 0 0 8px 0;
  padding: 15px 20px;
  overflow: auto;
  font-size: 20px;
}

.delete {
  margin: 0 0 8px auto;
  font-size: 1em;
}

@media screen and (max-width: 850px) {
  .gallery {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .gallery-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    margin: 0 0px 8px 0;
    overflow: auto;
    text-align: center;
  }
  .delete {
    justify-self: flex-end;
    margin: 0 0 8px auto;
    font-size: 20px;
  }
}