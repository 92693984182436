* {
  box-sizing: border-box;
}

.studio {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.studio-header {
  font-size: 40px;
}

.fas.fa-paint-brush {
  font-size: 30px;
}

.saved-canvas {
  border: black solid 3px;
  margin: 1% auto;
  text-align: center;
}

.loaded-canvas {
  border: black solid 3px;
  margin: 0 auto;
  text-align: center;
}

.color-picker-container {
  margin: 1% 2% 1%;
  text-align: center;
  align-self: flex-start;
}

.add-button {
  padding: 1% 2%;
  margin: 1%;
  font-size: 25px;
}

.tool-button {
  padding: 0.5% 0.75%;
  font-size: 25px;
}

#error-studio-message {
  color: red;
}

.gallery-select-container, .title-container, .brush-size-container {
  padding: 0.3%;
}

.brush-size-container {
  margin-bottom: 2px;
}

.select-label {
  font-size: 25px;
}

#art-gallery-select {
  margin: 1%;
  font-size: 25px;
}

#art-title-input {
  font-size: 25px;
  border: black 1px solid;
}

.brush-label, .title-label {
  padding: 0 1%;
}

.title-label {
  font-size: 25px;
}