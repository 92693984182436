* {
  box-sizing: border-box;
}

.gallery-page {
  list-style-type: none;
  margin: 150px 0 0px;
  min-height: 130vh;
  text-align: center;
  padding: 0;
  background-color: rgb(195, 158, 110);
  border-right: black solid 2px;
}

.gallery-list {
  list-style-type: none;
  margin: 20px 0 20px;
  padding: 0;
}

#error-message {
  font-size: 1.2em;
  color: darkred;
}

.gallery-list-names:nth-of-type(1) {
  background-color: red;
}

.gallery-list-names:nth-of-type(2) {
  background-color: orange;
}

.gallery-list-names:nth-of-type(3) {
  background-color: yellow;
}

.gallery-list-names:nth-of-type(4) {
  background-color: rgb(10, 151, 10);
}

.gallery-list-names:nth-of-type(5) {
  background-color: rgb(80, 160, 251);
}

.gallery-list-names:nth-of-type(6) {
  background-color: rgb(221, 40, 221);
}

.gallery-list-names:nth-of-type(7) {
  background-color: pink;
}

.button {
  padding: 12px 8px;
  margin: 0 5px 5px;
  border-radius: 10%;
  border: 1px solid gray;
  font-size: 1.2em;
  align-self: center;
}

.top-buttons {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
}

.logout-container {
  display: flex;
  justify-content: center;
  justify-content: center;
  align-content: center;
  padding-top: 5px;
  padding-right: 10px;
}

@media screen and (max-width: 850px) {
  .gallery-page {
    list-style-type: none;
    margin: 120px 0px 0;
    min-height: 30vh;
    padding-top: 20vh;
    border: solid 1px black;
    overflow: visible;
  }
  .gallery-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
  }
  .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
  }
  .logout-container {
    padding-top: 0px;
    padding-right: 0px;
  }
  .top-buttons {
    margin-top: 0px;
  }
  .button {
    font-size: 20px;
  }
  .button.studio {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .gallery-page {
    margin: 120px 0px 0;
    padding-top: 7vh;
  }
}

@media screen and (max-width: 320px) {
  .gallery-page {
    margin: 90px 0px 0;
    padding-top: 5vh;
  }
}