.landing-page {
  margin: 0 200px;
  padding-top: 30px;
}

p {
  font-size: 1.5em;
}

h2 {
  font-size: 2em;
}

.landing-image {
  height: auto;
  width: 100%;
}

.landing-image-container {
  width: 50vh;
  margin: 30px auto 0px;
}

.bottom-section {
  margin: 50px 20px;
}

.link-size {
  font-size: 1.4em;
}

.sample-user {
  font-size: 1.4em;
}

@media screen and (max-width: 850px) {
  .landing-page {
    padding-top: 0px;
    margin: 0px 10px;
    text-align: column;
  }
  .landing-image-container {
    width: 40vh;
    margin: 0px auto;
  }
  .bottom-section {
    margin: 50px 0px;
  }
  p {
    font-size: 1.4em;
  }
  h2 {
    font-size: 1.7em;
  }
}