.nav-landing-page {
  margin: 150px 0 0;
  min-height: 130vh;
  text-align: center;
  background-color: white;
  border-right: black solid 3px;
  background-color: rgb(44, 41, 41);
}

.sidebar-img {
  border-right: none;
  border-left: none;
}

.duck {
  border-bottom: none;
  margin-bottom: -4px;
}

.mountain {
  margin-bottom: -4px;
}

.bug {
  border-top: none;
  margin-bottom: -4px;
}

.box {
  height: 5vh;
}

.red-box {
  background-color: red;
}

.orange-box {
  background-color: orange;
}

.yellow-box {
  background-color: yellow;
}

.green-box {
  background-color: rgb(8, 170, 8);
}

.blue-box {
  background-color: rgb(55, 133, 236);
}

.purple-box {
  background-color: rgb(180, 72, 251);
}

@media screen and (max-width: 850px) {
  .nav-landing-page {
    display: flex;
    flex-direction: row;
    min-height: 0vh;
    height: 30vh;
    justify-content: center;
    align-content: center;
    margin: 180px auto 0;
    overflow: visible;
    padding: 90px 0px 200px;
  }
  .box {
    display: none;
  }
  .sidebar-img {
    width: 100%;
    height: auto;
  }
  .duck {
    border: solid 3px black;
    margin-bottom: 0px;
  }
  .mountain {
    border: solid 3px black;
    margin-bottom: 0px;
  }
  .bug {
    border: solid 3px black;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 450px) {
  .nav-landing-page {
    margin: 70px auto 0;
  }
}

@media screen and (max-width: 320px) {
  .nav-landing-page {
    margin: 30px 0px 0;
    overflow: visible;
  }
}