#gallery-name-input {
  padding: 5px;
  margin: 20px 5px;
}

.gallery-label {
  font-size: 22px;
  margin: 20px;
}

#gallery-form {
  padding-top: 50px;
}